@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Golos+Text:wght@400..900&display=swap");
body {
  margin: 0;
  font-family: "Golos Text", sans-serif;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  padding: 10px 40px;
}
.positioning {
  transform: translate3d(50%, 0, 0);
}

.inputStyle {
  @apply mt-3 block w-full rounded-lg border bg-white/5 py-1.5 px-3 text-sm/6 text-slate-500 focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25;
}

.pdfTable {
  /* width: 100%; */
  max-width: 700px;
  border-collapse: collapse;
  margin-top: 20px;
  margin-left: 20px;
  border-radius: 24px;
}

.anket > th {
  border: 1px solid #ccc;
  padding: 2px;
  text-align: left;
  background-color: #f2f2f2;
}
.anket > td {
  border: 1px solid #ccc;
  padding: 2px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.head {
  text-align: left;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
}
.value {
  font-size: 16px;
}
